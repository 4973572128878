// @file functions to set up listeners for native bridge
import postMessage from '@@/native_bridge/post_message'
import type { ListenedMessage, ListenedMessageHandler, ListenedMessageType } from '@@/native_bridge/types'

const listeners: { [messageType in ListenedMessageType]?: Function } = {}

/**
 * This is what the frontend calls when it wants to define behavior for when
 * the native app makes a call via native bridge.
 */
export function listen(messageType: ListenedMessageType, callback: ListenedMessageHandler): void {
  listeners[messageType] = callback
}

/**
 * This is what the native app will eventually call when it needs the
 * frontend to do something.
 * @returns true if found a listener to the message type, false otherwise.
 */
export function callListener(message: ListenedMessage): boolean {
  const func = listeners[message.message_type]
  if (func != null) {
    func(message)
    return true
  }
  return false
}

export function setupNativeBridgeListeners(window): void {
  if (!window.ww) window.ww = {}
  window.ww.nativeBridge = {
    ...window.ww.nativeBridge,
    addMessageHandler: listen,
    receiveMessage: callListener,
    postMessage,
  }
}
